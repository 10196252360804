// import React from 'react';
// import { Link } from 'react-router-dom';
// import { FaGithub, FaLinkedin } from 'react-icons/fa';
// import Galaxy from './Galaxy';

// function LandingPage() {
//     return (
//         <div className="relative font-roboto w-screen h-screen overflow-hidden">
//             <Galaxy />
//             <div className="absolute inset-0 flex flex-col justify-between z-10">
//                 <div className="absolute top-5 right-5 sm:right-10 sm:top-10 text-right text-sm md:text-lg">
//                     <ul className="flex flex-col sm:flex-row sm:items-center">
//                         <li className="my-2 sm:my-0 sm:mr-4">
//                             <Link to="/about-me" className="hover:underline">about me</Link>
//                         </li>
//                         <li className="my-2 sm:my-0 sm:mr-4">
//                             <Link to="/projects" className="hover:underline">projects</Link>
//                         </li>
//                         <li className="my-2 sm:my-0 sm:mr-4">
//                             <Link to="/awards" className="hover:underline">awards</Link>
//                         </li>
//                         <li className="flex items-center space-x-4 mt-4 sm:mt-0">
//                             <a
//                                 href="https://github.com/putt-t"
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                                 className="text-xl sm:text-2xl md:text-3xl hover:text-gray-500"
//                             >
//                                 <FaGithub />
//                             </a>
//                             <a
//                                 href="https://www.linkedin.com/in/nathaphat"
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                                 className="text-xl sm:text-2xl md:text-3xl hover:text-blue-600"
//                             >
//                                 <FaLinkedin />
//                             </a>
//                         </li>
//                     </ul>
//                 </div>

//                 <div className="absolute left-5 sm:left-10 top-5 sm:top-10 text-center sm:text-left">
//                     <h1 className="text-3xl sm:text-3xl md:text-4xl font-mono overflow-hidden">
//                         nathaphat.net
//                     </h1>
//                     <h2 className="text-xl sm:text-xl md:text-2xl mt-2 bg-gradient-to-r from-blue-500 to-purple-600 bg-clip-text text-transparent">
//                         data science + web dev
//                     </h2>
//                 </div>

//                 <div className="flex flex-col justify-center items-center h-screen px-4">
//                     <div className="mt-[-10vh] text-center">
//                         <h1 className="text-3xl sm:text-3xl md:text-5xl">
//                             working on&nbsp;
//                             <Link
//                                 to="/about-lamina"
//                                 className="bg-gradient-to-r from-blue-400 to-green-300 bg-clip-text text-transparent hover:text-blue-300 hover:underline transition duration-200"
//                             >
//                                 Lamina
//                             </Link>
//                         </h1>

//                         <p className="text-sm sm:text-sm md:text-base mt-4 italic">
//                             a foundation engineering web app
//                         </p>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }



// export default LandingPage;

import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
function LandingPage() {
  return (
    <div className="relative min-h-screen font-inter">
      {/* <Galaxy /> */}

      {/* Header */}
      <header className="mb-8">
        <nav className="flex justify-between items-center p-4 sm:p-10">
          <div className="text-xl font-bold text-gray-800">nathaphat t.</div>
          <div className="flex items-center space-x-4">
            {/* GitHub Link */}
            <a
              href="https://github.com/putt-t"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 hover:text-gray-900"
              aria-label="GitHub"
            >
              <FaGithub className="w-6 h-6" />
            </a>
            {/* LinkedIn Link */}
            <a
              href="https://www.linkedin.com/in/nathaphat"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 hover:text-gray-900"
              aria-label="LinkedIn"
            >
              <FaLinkedin className="w-6 h-6" />
            </a>
          </div>
        </nav>
      </header>

      <main className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
        <section className="mb-10">
          <h1 className="text-2xl sm:text-3xl font-bold mb-2 text-gray-800">hi there.</h1>
          <p className="text-gray-600">
            undergrad data science @ UCSD.
          </p>
        </section>

        <section className="mb-10">
          <h1 className="text-2xl sm:text-3xl font-bold mb-2 text-gray-800">what am i up to?</h1>
          <p className="text-gray-600">
            <span>working on </span>
            <a
              href="https://github.com/putt-t/db-go"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline text-blue-400"
            >
              dbgo
            </a> - recreating a database in golang.
          </p>
          <p className="text-gray-600 mt-2">
            learning rust.
          </p>
          <p className="text-gray-600 mt-2">
            rebuilding this website and working on machine learning projects.
          </p>
        </section>

        <section className="mb-2 mt-2">
          <h1 className="text-2xl sm:text-3xl font-bold mb-2 text-gray-800">reading:</h1>
          <p className="text-gray-600 mt-2">
            <a
              href="https://www.amazon.com/Designing-Data-Intensive-Applications-Reliable-Maintainable/dp/1449373321"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline text-blue-400"
            >
              Designing Data-Intensive Applications
            </a>
            <br />
            <a
              href="https://www.amazon.com/Art-Doing-Science-Engineering-Learning/dp/1732265178"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline text-blue-400"
            >
              The Art of Doing Science and Engineering: Learning to Learn
            </a>
          </p>
        </section>
      </main>
    </div>
  );
}

export default LandingPage;